<template>
  <pool title="政治面貌" @chooseCount="chooseCount" :dataList="showDataList" :countList="countList" v-loading="loading"></pool>
</template>

<script>
import {
  loadDetail,
  detailFilter,
  getCountData
} from './lib'

import pool from './pool'
export default {
  data () {
    return {
      dataList: [],
      searchParm: {
        politicalStatus: null
      },
      loading: false
    }
  },
  components: {
    pool
  },
  computed: {
    politicalStatusList () {
      return this.$store.getters.selectDatas['worker_political_status_list'].map(v => {
        return {
          name: v.label
        }
      })
    },
    showDataList () {
      return this.dataList.filter(v => detailFilter(v, this.searchParm))
    },
    countList () {
      let data = getCountData(this.dataList, this.politicalStatusList, 'politicalStatus', '无政治面貌')
      data.forEach(v => v.active = this.searchParm.politicalStatus === v.key)
      return data
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    chooseCount (cIndex) {
      let politicalStatus = this.countList[cIndex].key
      if (this.searchParm.politicalStatus === politicalStatus) {
        this.searchParm.politicalStatus = null
      } else {
        this.searchParm.politicalStatus = politicalStatus
      }
    },
    async loadData () {
      this.loading = true
      this.dataList = await loadDetail(this)
      this.loading = false
    }
  }
}
</script>
